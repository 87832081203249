<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">基础信息管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/personRoleInfo">用户身份管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
      <el-form-item label="适用场景" prop="sceneId">
        <el-select
          v-model="queryModel.sceneId"
          filterable
          placeholder="请选择"
          style="width: 110px"
          size="mini"
        >
          <el-option
            v-for="result in sceneData"
            :key="result.id"
            :label="result.sceneName"
            :value="result.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否启用" prop="useEnable">
        <el-select
          v-model="queryModel.useEnable"
          filterable
          placeholder="请选择"
          style="width: 80px"
          size="mini"
        >
          <el-option label="全部" value=""></el-option>
          <el-option label="是" value="1"></el-option>
          <el-option label="否" value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
          >查询</el-button
        >&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
          >重置</el-button
        >&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        @click="handleAdd"
        >新增</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchDelete"
        >删除选中项</el-button
      >
    </el-row>
    <el-table
      :data="tableData"
      style="min-height: 400px"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        prop="personRoleName"
        label="身份名称"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="parentName"
        label="上级身份"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="sceneName"
        label="适用场景"
        width="180"
      ></el-table-column>
      <el-table-column prop="useEnable" label="是否启用" width="180">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.useEnable"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="updateEnabled(row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="registerEnable" label="注册时可选" width="180">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.registerEnable"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="updateRegisterEnabled(row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="registerEnable" label="首页菜单设置" width="180">
        <template slot-scope="{ row }">
          <el-link type="primary" @click="showIconList(row,'common')">通用菜单</el-link>-
          <el-link type="primary" @click="showIconList(row,'default')">默认菜单</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
      <el-table-column label="操作" fixed="right" width="200">
        <template slot-scope="{ row }">
          <el-button size="mini" type="warning" @click="handleEdit(row)"
            >编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(row)"
            >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <personRoleInfo-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      :sceneData="sceneData"
      @close="onDetailModalClose"
    ></personRoleInfo-detail>
    <personRoleInfo-icon
      v-if="showIconModal"
      :personRoleId="businessKey"
      :title="iconModalTitle"
      :mode="iconMode"
      @close="onIconModalClose"
    ></personRoleInfo-icon>
  </div>
</template>
<script>
import Constant from "@/constant";
import PersonRoleInfoDetail from "./personRoleInfo-detail";
import PersonRoleInfoIcon from "./personRoleInfo-icon";
import personRoleInfoApi from "@/api/base/personRoleInfo";
import sceneInfoApi from "@/api/base/sceneInfo";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "basePersonRoleInfoList",
  data() {
    var self = this;

    return {
      queryModel: {
        sceneId: "",
        useEnable: "",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      sceneData: [],
      iconMode: "common",
      showIconModal: false,
      iconModalTitle: ""
    };
  },
  created() {
    sceneInfoApi.list().then((response) => {
      var jsonData = response.data;
      this.sceneData = jsonData.data;
    });
  },
  methods: {
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("sceneId", self.queryModel.sceneId);
      formData.append("useEnable", self.queryModel.useEnable);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      personRoleInfoApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
      this.sceneData = this.sceneData;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
      this.sceneData = this.sceneData;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          personRoleInfoApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        personRoleInfoApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    onIconModalClose(){
      this.showIconModal = false;
    },
    updateEnabled(row) {
      personRoleInfoApi.update(row);
    },
    updateRegisterEnabled(row) {
      personRoleInfoApi.update(row);
    },
    showIconList(row,mode) {
      this.iconMode = mode;
      this.businessKey = row.id;

      if(mode=="common"){
        this.iconModalTitle = "通用菜单设置";
      }
      else{
        this.iconModalTitle = "默认菜单设置";
      }

      this.showIconModal = true;
    }
  },
  mounted: function () {
    this.changePage(1);
  },
  components: {
    "personRoleInfo-detail": PersonRoleInfoDetail,
    "personRoleInfo-icon" : PersonRoleInfoIcon
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>