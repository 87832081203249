<template>
    <el-dialog
      :visible.sync="showDialog"
      :title="title"
      width="1000px"
      :modal-append-to-body="false"
      style="text-align:left;"
      @close="closeDialog"
      :close-on-click-modal="false"
    >
    <div class="flex-row">
      <div class="role-permission">
          <el-transfer
          v-model="relatedMenus"
          :data="menuList"
          v-loading="loading"
          filterable
          target-order="push"
          :filter-method="filterMethod"
          filter-placeholder="请输入关键字"
          :props="{key: 'id',label: 'description'}"
          :titles="['未分配菜单','已分配菜单']"
          @right-check-change="rightCheckChange"
          ></el-transfer>
      </div>
      <div class="flex-col">
        <div>
          <el-button round size="mini" @click="moveUp">上移</el-button>
          <div style="height:10px;"></div>
          <el-button round size="mini" @click="moveDown">下移</el-button>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
    </el-dialog>
</template>
<script>
import personRoleApi from "@/api/base/personRoleInfo";

export default {
  props: ["personRoleId","mode","title"],
  data() {
    return {
      menuList: [],
      relatedMenus: [],
      loading: false,
      showDialog: true,
      submitting: false,
      rightCheckedItems: []
    };
  }, 
  methods: {
    closeDialog() {
      this.$emit("close",false);
    },
    handleSubmit() {
      var self = this;

      personRoleApi
        .saveRelatedIcon(this.personRoleId,this.mode,this.relatedMenus)
        .then(function(response) {
          var jsonData = response.data;
          self.submitting = false;

          if (jsonData.result) {
            self.$message({
              message: "保存成功!",
              type: "success"
            });

            self.$emit("close",true);
          } else {
            self.$message({
              message: jsonData.message + "",
              type: "warning"
            });

            self.$emit("close",false);
          }
        });
    },
    filterMethod(query, item) {
      return item.description.indexOf(query) > -1;
    },
    queryRelatedMenuList() {
      var self = this;

      self.loading = true;

      self.menuList = [];
      self.relatedMenus= [];

      personRoleApi.findAllIcon(this.personRoleId)
        .then(response => {
          var jsonData = response.data;

          self.menuList = jsonData.data.map(item => {
            return {
              id: item.id,
              description: "[" + item.iconTypeName + "]" + item.iconName
            };
          });

          return personRoleApi.findRelatedIcon(self.personRoleId,self.mode);
        })
        .then(response => {
          var jsonData = response.data;

          self.relatedMenus = jsonData.data.map(item => {
            return item.iconId;
          });

          //console.log(self.relatedMenus);

          self.loading = false;
        });
    },
    rightCheckChange(checkedItems) {
      this.rightCheckedItems = checkedItems;
    },
    moveUp() {
      this.rightCheckedItems.forEach(id=>{
        var index = this.relatedMenus.indexOf(id);

        if(index>0){
          console.log(index + "," + id);
          this.relatedMenus.splice(index,1);
          this.relatedMenus.splice(index-1,0,id);
        }
      })
    },
    moveDown() {
      this.rightCheckedItems.forEach(id=>{
        var index = this.relatedMenus.indexOf(id);

        if(index<this.relatedMenus.length-1){
          console.log(index + "," + id);
          this.relatedMenus.splice(index,1);
          this.relatedMenus.splice(index+1,0,id);
        }
      })
    }
  },
  mounted() {
    this.queryRelatedMenuList();
  }
};
</script>
<style lang="scss">
.flex-row{
  display:flex;
  flex-direction: row;
}

.flex-col{
  display: flex;
  flex-direction: col;
  justify-content: center; /* 水平居中 */
  align-items: center;     /* 垂直居中 */
}
</style>
