
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align: left"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'20%'"
      >
        <el-form-item label="用户身份名称" prop="personRoleName">
          <el-input
            v-model="formModel.personRoleName"
            placeholder="请输入用户身份名称"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="上级身份" prop="parentId">
          <el-select
            v-model="formModel.parentId"
            filterable
            clearable
            placeholder="请选择"
            style="width: 300px"
          >
            <el-option
              v-for="result in personRoleList"
              :key="result.id"
              :label="result.personRoleName + '-' + result.sceneName"
              :value="result.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="适用于与场景" prop="sceneId">
          <el-select
            v-model="formModel.sceneId"
            filterable
            placeholder="请选择"
            style="width: 200px"
          >
            <el-option
              v-for="result in sceneData"
              :key="result.id"
              :label="result.sceneName"
              :value="result.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否启用" prop="useEnable">
          <el-switch
            v-model="formModel.useEnable"
            active-color="#13ce66"
            inactive-color="#ff4949"
          ></el-switch>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="formModel.remark"
            placeholder="请输入备注"
            style="width: 300px"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import personRoleInfoApi from "@/api/base/personRoleInfo";

export default {
  props: ["businessKey", "title", "sceneData"],
  data() {
    return {
      formModel: {},
      ruleValidate: {
        id: [{ required: true, message: "不能为空", trigger: "blur" }],
        personRoleName: [
          { required: true, message: "用户身份名称不能为空", trigger: "blur" },
        ],
        sceneId: [
          {
            required: true,
            message: "适用于与场景不能为空",
            trigger: "blur",
          },
        ],
      },
      showDialog: true,
      loading: false,
      submitting: false,
      personRoleList: []
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return personRoleInfoApi.add(self.formModel);
            } else {
              return personRoleInfoApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  mounted: function () {
    var self = this;

    personRoleInfoApi.findAll()
    .then(response=>{
      var jsonData = response.data;

      if(jsonData.result){
        this.personRoleList = jsonData.data;
      }
    });

    (function () {
      if (self.formModel.useEnable == null) {
        self.formModel.useEnable = 0;
      }

      if (self.businessKey.length == 0) {
        return personRoleInfoApi.create();
      } else {
        return personRoleInfoApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>